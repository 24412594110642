import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import styled from 'styled-components';

import { media } from './theme';


const Slide = ({ alt, asset, style, caption }) => {
  
  return (
    <GallerySlide className="gallery-slide" style={style}>
      <GalleryImage className="gallery-image" fluid={asset.fluid} alt={alt} objectFit="contain" />
      <Caption className="gallery-caption">{caption}</Caption>
    </GallerySlide>
  );

};


const GallerySlide = styled.div`
  position: relative;
  height: 100%;
  display: flex !important;
  flex-direction: column;
`;

const GalleryImage = styled(Img)`
  flex-grow: 1;
`;

const Caption = styled.p`
  font-weight: ${({ theme }) => theme.font.semibold};
  line-height: 1.6;
  margin-top: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  ${media.break`
    padding-left: 0;
    padding-right: 0;
  `}
`;
export default Slide;