import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image/withIEPolyfill';


const SliderNav = ({ photos, sliderRef }) => {
  return (
    <StyledSliderNav className="slider-nav">
      {photos.map(({ _key, image, alt }, i) => (
        <SliderNavButton key={_key} onClick={() => {
          sliderRef.current.slickGoTo(i);
        }}>
          <SliderNavImg className="nav-slider-image" fluid={image.asset.fluid} alt={alt} objectFit="cover" />
        </SliderNavButton>
      ))}
    </StyledSliderNav>
  );

};

const StyledSliderNav = styled.nav`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`;

const SliderNavButton = styled.button`
  background: transparent;
  border: 0;
  padding: 0;
  margin: 1rem;
  cursor: pointer;
  outline: none;
`;

const SliderNavImg = styled(Img)`
  width: 80px;
  height: 80px;
`;

export default SliderNav;