import React, { useRef } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Slide from './Slide';
import SliderNav from './SliderNav';

import useSiteContext from './SiteContext';
import { media } from './theme';


const Gallery = ({ photos }) => {

  const { viewport, mobile } = useSiteContext();
  const sliderRef = useRef(null);
  
  console.log(photos);
 
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: !mobile
  };

  return (
    <StyledGalleryWrapper className="gallery-wrapper" viewHeight={viewport.height}>
      {!mobile && <SliderNav photos={photos} sliderRef={sliderRef} />}
      <StyledSlider className="gallery-slider" viewHeight={viewport.height} {...settings} id="mainSlider" ref={sliderRef}>
        {photos.map(image => {
          return <Slide key={image._key} {...image.image} caption={image.caption} />
        })}
      </StyledSlider>
      {mobile && <SliderNav photos={photos} sliderRef={sliderRef} />}
    </StyledGalleryWrapper>
  );
};


const StyledSlider = styled(Slider)`
  ${media.break`
    height: ${({ theme }) => `calc(100vh - ${theme.headerHeight.desktop - 86 - 100 - 40}px)`};
    height: ${({ theme, viewHeight }) => viewHeight - theme.headerHeight.desktop - 86 - 100 - 40}px;
    max-height: ${({ theme }) => `calc(100vh - ${theme.headerHeight.desktop - 86 - 100 - 40}px)`};
    max-height: ${({ theme, viewHeight }) => viewHeight - theme.headerHeight.desktop - 86 - 100 - 40}px;
    .slick-list {
      max-height: 100%;
      height: 100%;
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
    .slick-track {
      max-height: 100%;
      height: 100%;
    }
    .slick-slide {
      max-height: 100%;
      > div {
        height: 100%;
      }
    }
    .gallery-slide {
      max-height: 100%;
      .gatsby-image-wrapper {
        max-height: 100%;
      }
    }
  `}

  .slick-arrow {
    width: 30px;
    height: 30px;
    &.slick-prev {
      transition: 1s;
      transform: rotate(180deg);
    }
    ::before {
      position: absolute;
      content: '';
      width: 20px;
      height: 4px;
      background: ${({ theme }) => theme.purple};
      transform-origin: right;
      border-radius: 10rem;
      left: 50%;
      transform: translateX(-50%) rotate(45deg) translateY(1.5px);
    }
    ::after {
      position: absolute;
      content: '';
      width: 20px;
      height: 4px;
      background: ${({ theme }) => theme.purple};
      transform-origin: right;
      border-radius: 10rem;
      left: 50%;
      transform: translateX(-50%) rotate(-45deg) translateY(-1.5px);
    }
  }
`;

const StyledGalleryWrapper = styled.div`
  flex-grow: 1;

  ${media.break`  

    

  `}
`;
export default Gallery;