import React from 'react';
import { graphql } from 'gatsby';

import Wrapper from '../components/Wrapper';
import GalleryPage from '../components/GalleryPage';

const galleryPage = ({ data, location }) => {
  const { sanityGalleryPage } = data;
  return (
    <Wrapper seo={{seoSettings: sanityGalleryPage.seoSettings, title: sanityGalleryPage.title }} location={location}>
      <GalleryPage {...data.sanityGalleryPage} />
    </Wrapper>
  )
}

export const GalleryPageQuery = graphql`
  query galleryPageQuery {
    sanityGalleryPage(_id: {eq: "galleryPage"}) {
      gallery {
        _key
        _type
        caption
        image {
          alt
          asset {
            fluid(maxWidth: 800) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      _rawContent
      title
    }
  }
`;

export default galleryPage;
