import React from 'react';
import styled from 'styled-components';

import Gallery from './Gallery';
import Footer from './Footer';

import useSiteContext from './SiteContext';
import { media } from './theme';


const GalleryPage = ({ gallery, _rawContent, title }) => {

  const { viewport, mobile } = useSiteContext();

  return (
    <GalleryPageWrapper viewHeight={viewport.height} className="page-wrapper gallery">
      <h1 className="page-title">{title}</h1>
      <Gallery photos={gallery} />
      {!mobile && <Footer fullscreen />}
    </GalleryPageWrapper>
  );
};

const GalleryPageWrapper = styled.div`
  max-width: 100%;
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 4rem;
  padding-left: 1rem;
  padding-right: 1rem;
  ${media.break`
    display: flex;
    flex-direction: column;
    height: ${({ theme }) => `calc(100vh - ${theme.headerHeight.desktop}px)`};
    height: ${({ viewHeight, theme }) => viewHeight - theme.headerHeight.desktop}px;
    max-height: ${({ theme }) => `calc(100vh - ${theme.headerHeight.desktop}px)`};
    max-height: ${({ viewHeight, theme }) => viewHeight - theme.headerHeight.desktop}px;
  `}
  .page-title {
    color: ${({ theme }) => theme.purple};
    margin-bottom: 5rem;
  }
`;

export default GalleryPage;